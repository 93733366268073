<template>
  <div class="container">
    <div v-if="!hasPorkMeatSales">
      <h1>Meat from Iberian Pigs</h1>
      <p>This feature is not yet available.</p>
    </div>
    <div v-if="hasPorkMeatSales" class="container">
      <h1>Meat from Iberian Pigs</h1>
      <div class="introduction">
        <figure>
          <img src="@/assets/images/pigOutline.jpg" />
          <figcaption>{{ animal.externalId }}</figcaption>
        </figure>
        <div>
          <p>
            Meat is a very perishable product. When we have animals for sale we offer them one by one to ensure that
            all
            pieces have been sold before the animal gets processed.
          </p>
          <div class="percentage-bar">
            <div v-if="carcass.percentageSold > 0" class="sold-bar" :style="calculateSold(carcass.percentageSold)">{{
              carcass.percentageSold
            }} % reserved</div>
            <div v-if="carcass.percentageSold == 0"> Nothing reserved yet </div>
          </div>

          <p>
            An adult Iberian pig weights about 170 kg. Not everything can be eaten by humans. However, we do try to
            find
            a
            sensible use for each part. Below you see all the pieces that can be obtained from a pig carcass.
          </p>
        </div>
      </div>

      <h2>Meat Cuts Available</h2>
      <p>
        <b>Please keep in mind that we do not slaugther and process animals in advance.</b> The meat will be shipped
        in a
        cooling pack, fresh and using a fast shipper. There are two ways to buy:
      </p>
      <dl>
        <dt>Buy today</dt>
        <dd>The major cuts are what we call <em>trigger pieces</em>. Once all of these have been bought the animal is
          processed.</dd>

        <dt>Get on the waiting list</dt>
        <dd>As processing of the animal starts only once all <em>Buy Today</em> items have been sold, the waiting list
          items are processed at that time but do not trigger the processing.
        </dd>
      </dl>
      <p>
        Once all trigger pieces (<em>Buy Today</em>) have been sold the next animal, if available, will be offered for
        sale. <b>Out of fairness to others, pre-orders will be removed after 24 hours if not paid.</b>
      </p>

      <h3>Kitchen Ready Pieces</h3>
      <CookieControlledContent>
        <div class="meat-selection-area">
          <div>
            <p v-if="showMeatPiecesAvailable">
              The weight shown for each piece is aproximately and may vary slightly. Each piece comes in a vacuum sealed
              bag.
              You may put the bags into your freezer.
            </p>
            <table v-if="showMeatPiecesAvailable">
              <thead>
                <tr>
                  <th>Cut</th>
                  <th>Weight</th>
                  <th>Qty</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="piece in kitchenReadyPieces()" :key="piece.name">
                  <td>{{ piece.name }}</td>
                  <td>{{ $n(piece.weight, decimal) }} g</td>
                  <td>{{ piece.quantity }}</td>
                  <td>{{ piece.priceInCents / 100 }} €</td>
                  <td>
                    <button class="buy-today" v-if="piece.triggerPiece" :disabled="piece.quantity == 0"
                      @click.stop="selectPiece(piece)">Buy
                      Today</button>
                    <button class="waiting-list" v-if="!piece.triggerPiece" :disabled="piece.quantity == 0"
                      @click.stop="selectPiece(piece)">Waiting
                      List</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="showPaypalButtons || showSuccess">
              <div v-if="showPaypalButtons" id="paypalButtons" />

              <div v-if="showSuccess" class="purchase-success">
                <p>{{ $t("shoppingCart.purchaseThankYou") }}</p>

                <div class="purchase-data">
                  <div>{{ purchaseRecord.customer.shipping_details.full_name }}</div>
                  <div>{{ purchaseRecord.customer.shipping_details.address.address_line_1 }}</div>
                  <div>{{ purchaseRecord.customer.shipping_details.address.address_line_2 }}</div>
                  <div>
                    {{ purchaseRecord.customer.shipping_details.address.postal_code }} {{
                      purchaseRecord.customer.shipping_details.address.admin_area_1
                    }}, {{ purchaseRecord.customer.shipping_details.address.admin_area_2 }}
                  </div>
                  <div>{{ purchaseRecord.customer.shipping_details.address.country_code }}</div>
                </div>

                <p> {{ $t("shoppingCart.checkEmail") }}</p>
                <p class="purchase-data">{{ purchaseRecord.customer.email }}</p>
              </div>
            </div>
          </div>

          <div>
            <table class="selection" :class="{ overlayDisable: !showPurchaseOptions }">
              <thead>
                <tr>
                  <th colspan="4">Your Selection</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="error">
                  <td class="errorMessage">{{ error }}</td>
                </tr>
                <tr v-if="selectedItems.length == 0">
                  <td colspan="3">Nothing selected yet</td>
                </tr>
                <tr v-for="item in selectedItems" :key="item.meatPieceId">
                  <td @click.stop="removeFromSelection(item)">
                    <mdicon name="close-circle-outline" />
                  </td>
                  <td class="item-name">{{ item.name }}</td>
                  <td>{{ item.quantity }}</td>
                  <td class="slider">
                    <Slider :disabled="!showPurchaseOptions" v-model="item.customerPrice" :min="item.minimumPrice"
                      :max="item.maximumPrice" :format="format" :tooltipPosition="top" @change="sliderChange" />
                  </td>
                </tr>
                <tr class="total-price">
                  <td>Total</td>
                  <td colspan="2">{{ selectedItemsTotal }} €</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button class="purchase" v-if="!showPaypalButtons" @click="performPurchase">{{
          $t("shoppingCart.buttonPurchase")
        }}</button>
        <OutsideEURestrictions />

        <div class="information-area" v-if="showAdditionalPieces">
          <h3>Pieces that require curing</h3>
          <p>
            Curing is a long process. In Spain the pieces are put into salt for several months and then cure in ripening
            chambers for 3-5 years.
          </p>
          <table>
            <thead>
              <tr>
                <th>Cut</th>
                <th>Weight</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="piece in piecesForCuring()" :key="piece.name">
                <td>{{ piece.name }}</td>
                <td>{{ $n(piece.weight, decimal) }} g</td>
                <td>{{ piece.quantity }}</td>
              </tr>
            </tbody>
          </table>

          <h3>Other Pieces</h3>
          <p>
            This is the remainder of the eatable parts of the animal.
          </p>
          <table>
            <thead>
              <tr>
                <th>Cut</th>
                <th>Weight</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="piece in otherPieces()" :key="piece.name">
                <td>{{ piece.name }}</td>
                <td>{{ $n(piece.weight, decimal) }} g</td>
                <td>
                  <span v-if="piece.willBeTransformed">will be transformed</span>
                  <button v-if="piece.waitingList">Select</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </CookieControlledContent>
    </div>
  </div>
</template>

<style src="@vueform/slider/themes/default.css">

</style>

<script>
import { setupFeatureFlags } from '@/library'
import { axiosInstance } from '@/library'
import Slider from '@vueform/slider'
import { loadScript } from "@paypal/paypal-js"
import OutsideEURestrictions from '../../components/OutsideEURestrictions.vue'
import CookieControlledContent from '../../components/CookieControlledContent.vue';

export default {
  name: "IbericoMeat",
  components: {
    Slider,
    OutsideEURestrictions,
    CookieControlledContent
  },

  data: () => ({
    error: '',
    animal: {},
    carcass: {
      percentageSold: 0
    },
    format: function (value) {
      return `${Math.round(value)} €`
    },
    animals: [],
    meatPieces: [],
    selectedItems: [],
    purchaseRecord: {},
    showPaypalButtons: false,
    showPurchaseOptions: true,
    showAdditionalPieces: true,
    showSuccess: false,
    showMeatPiecesAvailable: true
  }),

  setup() {
    return setupFeatureFlags()
  },

  computed: {
    animalCount() {
      return this.animals.length
    },

    selectedItemsTotal() {
      let total = 0

      this.selectedItems.forEach((item) => {
        total += item.customerPrice * item.quantity
      })

      return total
    },

  },

  beforeMount() {
    this.loadData()
  },

  methods: {
    calculateSold(percentage) {
      return 'width: ' + percentage + '%'
    },

    calculateRemainder(percentage) {
      return 'width: ' + 100 - percentage + '%'
    },

    kitchenReadyPieces() {
      const pieces = this.meatPieces.filter(item => item.willBeTransformed == false && item.requiresCuring == false)
      return pieces
    },

    otherPieces() {
      return this.meatPieces.filter(item => item.willBeTransformed == true)
    },

    piecesForCuring() {
      return this.meatPieces.filter(item => item.requiresCuring == true)
    },

    loadData() {
      const customerId = this.getCustomerId()

      axiosInstance.get(`${window.VUE_APP_GRANJA}/api/butchershop/next/PIG/IBERICO`)
        .then(response => {
          if (response.status === 200) {
            this.animal = response.data

            axiosInstance.get(`${window.VUE_APP_GRANJA}/api/butchershop/pre-orders/${this.animal.animalId}`)
              .then((response) => {
                this.carcass = response.data
                console.log('carcass loaded', response.data)

                // TODO move these into functions that work on carcass
                this.meatPieces = []
                Object.keys(response.data.availableMeatPieces).forEach((pieceId) => {
                  const piece = response.data.availableMeatPieces[pieceId]
                  console.log('loading piece ', piece)
                  this.meatPieces.push(piece)
                })

                this.selectedItems = []
                response.data.meatPreOrders.forEach((item) => {
                  if (item.customerId === customerId) {
                    const piece = response.data.availableMeatPieces[item.meatPieceId]

                    this.selectedItems.push({
                      meatPieceId: piece.meatPieceId,
                      name: piece.name,
                      quantity: item.quantity,
                      customerPrice: item.customerPriceInCents / 100,
                      minimumPrice: piece.minimumPriceInCents / 100,
                      maximumPrice: piece.maximumPriceInCents / 100
                    })
                  }
                })
              })
          }
        })
    },

    removeFromSelection(item) {
      axiosInstance.delete(`${window.VUE_APP_GRANJA}/api/butchershop/pre-orders/${this.animal.animalId}/${this.getCustomerId()}/${item.meatPieceId}`)
        .then(() => {
          this.loadData()
        })
    },

    getCustomerId() {
      if (this.$cookieConsent.accepted()) {
        let customerId = this.$cookies.get('meatCustomerId')
        if (customerId == null) {
          console.log('no customer cookie')
          axiosInstance.post(`${window.VUE_APP_GRANJA}/api/sales/new-customer`, JSON.stringify({
            language: navigator.language
          }), {
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then((response) => {
              customerId = response.data.customerId
              console.log('setting cookie to ', customerId)
              this.$cookies.set('meatCustomerId', customerId)
              console.log('returning customer id ', customerId)
              return customerId
            })
            .catch(() => {
              this.error = 'Cannot create customer ID. Please contact support.'
              return null
            })
        } else
          return customerId
      } else
        return null
    },

    selectPiece(piece) {
      console.log('select piece', piece)
      let payload = {}

      if (this.selectedItems.filter(e => e.meatPieceId === piece.meatPieceId).length == 0) {
        this.selectedItems.push({
          meatPieceId: piece.meatPieceId,
          name: piece.name,
          quantity: 1,
          customerPrice: piece.priceInCents / 100,
          minimumPrice: piece.minimumPriceInCents / 100,
          maximumPrice: piece.maximumPriceInCents / 100
        })
        payload = {
          animalId: this.animal.animalId,
          meatPieceId: piece.meatPieceId,
          quantity: 1,
          customerPriceInCents: piece.priceInCents,
          customerId: this.getCustomerId()
        }
      } else {
        if (piece.quantity > 0) {
          const existingItem = this.selectedItems.filter(e => { return e.meatPieceId === piece.meatPieceId })
          existingItem[0].quantity += 1

          payload = {
            animalId: this.animal.animalId,
            meatPieceId: existingItem[0].meatPieceId,
            quantity: 1,
            customerPriceInCents: existingItem[0].priceInCents,
            customerId: this.getCustomerId()
          }
        }
      }

      axiosInstance.post(`${window.VUE_APP_GRANJA}/api/butchershop/pre-orders`, JSON.stringify(payload))
        .then(() => {
          this.loadData()
        })
    },

    sliderChange() {
      // TODO figure out how to avoid the delay
      new Promise(res => setTimeout(res, 100)).then(() => {
        this.selectedItems.forEach((item) => {
          item.customerPriceInCents = item.customerPrice * 100
        })

        const payload = []
        this.selectedItems.forEach((item) => {
          payload.push({
            animalId: this.animal.animalId,
            meatPieceId: item.meatPieceId,
            quantity: item.quantity,
            customerPriceInCents: item.customerPriceInCents,
            customerId: this.getCustomerId()
          })
        })


        axiosInstance.put(`${window.VUE_APP_GRANJA}/api/butchershop/pre-orders/${this.animal.animalId}`,
          JSON.stringify(payload))
      })

    },

    afterPurchase(purchaseRecord) {
      purchaseRecord.customerId = this.getCustomerId()

      axiosInstance.post(`${window.VUE_APP_GRANJA}/api/sales/pre-order-payment`, JSON.stringify(purchaseRecord), {
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(() => {
          this.purchaseRecord = purchaseRecord
          this.showMeatPiecesAvailable = false
          this.showPaypalButtons = false
          this.showSuccess = true
        })
    },

    performPurchase() {
      this.showAdditionalPieces = false
      this.showPurchaseOptions = false
      this.showMeatPiecesAvailable = false
      this.showPaypal = true
      const afterPurchaseFunction = this.afterPurchase
      const checkoutPrice = this.selectedItemsTotal.toString()

      this.showPaypalButtons = true
      loadScript({
        "client-id": window.VUE_APP_PAYPAL_CLIENT_ID,
        "currency": "EUR"
      })
        .then((paypal) => {
          paypal
            .Buttons({
              createOrder: (data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: checkoutPrice
                    }
                  }]
                })
              },
              onApprove: (data, actions) => {
                return actions.order.capture().then(function (orderData) {
                  console.log('orderData ', orderData)
                  const purchaseRecord = {
                    payment: {
                      amountInCents: orderData.purchase_units[0].payments.captures[0].amount.value * 100,
                      transactionId: orderData.purchase_units[0].payments.captures[0].id,
                      paymentProvider: 'PAYPAL',
                      timestamp: orderData.purchase_units[0].payments.captures[0].update_time
                    },
                    customer: {
                      email: orderData.payer.email_address,
                      phone: orderData.payer.phone,
                      shipping_details: {
                        full_name: orderData.purchase_units[0].shipping.name.full_name,
                        address: {
                          address_line_1: orderData.purchase_units[0].shipping.address.address_line_1,
                          address_line_2: orderData.purchase_units[0].shipping.address.address_line_2,
                          admin_area_1: orderData.purchase_units[0].shipping.address.admin_area_1,
                          admin_area_2: orderData.purchase_units[0].shipping.address.admin_area_2,
                          postal_code: orderData.purchase_units[0].shipping.address.postal_code,
                          country_code: orderData.purchase_units[0].shipping.address.country_code
                        }
                      }
                    }
                  }
                  afterPurchaseFunction(purchaseRecord)
                })
              }
            })
            .render("#paypalButtons")
            .catch((error) => {
              console.error("failed to render the PayPal Buttons", error);
            });
        })
        .catch((error) => {
          console.error("failed to load the PayPal JS SDK script", error);
        });
    },
  }
}
</script>

<style scoped>
@media (max-width:440px) {
  #paypalButtons {
    width: 100vw;
  }
}

TABLE {
  border-collapse: separate;
  border-spacing: 30px 0;
}

.meat-selection-area TABLE {
  width: 100%;
}

.meat-selection-area P {
  margin-top: 0;
  font-size: smaller;
}

TH {
  text-align: left
}

@media (max-width:440px) {
  TABLE.selection {
    margin-top: 3em;
    width: 100vw;
  }
}

TABLE.selection TD {
  height: 60px;
}

INPUT {
  border: thin black solid;
  padding: 2px;
  padding-left: 5px;
}

TD.item-name {
  width: 40%;
}

TD.slider {
  width: 100%;
}

.total-price {
  font-weight: bold;
}

INPUT.quantity {
  width: 3em;
}

.overlayDisable {
  background-color: rgba(184, 183, 183, 0.5);
  z-index: 2;
}

figure {
  padding: 0;
  margin: 0;
}

@media (max-width:440px) {
  figure {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

figcaption {
  display: table-caption;
  caption-side: bottom;
  padding-bottom: 1em;
  text-align: center;
  color: black;
  background-color: white;
  position: relative;
  height: 17px;
  bottom: 44px;
  width: 250px;
  padding-top: 8px;
  opacity: 0.8;
}

DIV.introduction {
  display: flex;
  gap: 4em;
}

@media (max-width:440px) {
  DIV.introduction {
    gap: unset;
    flex-wrap: wrap;
  }
}

DIV.information-area {
  margin-top: 3em;
}

DIV.percentage-bar {
  width: 100%;
  background-color: lightgray;
}

DIV.percentage-bar .sold-bar {
  padding: 0.5em;
  background-color: rgb(142, 212, 142);
}

DIV.meat-selection-area {
  display: grid;
  grid-template-columns: auto 50%;
  column-gap: 2em;
  margin-bottom: 1em;
}

@media (max-width:440px) {
  DIV.meat-selection-area {
    display: flex;
    flex-wrap: wrap;
    column-gap: unset;
  }
}

BUTTON.buy-today {
  font-size: smaller;
  background-color: rgb(251, 178, 95);
  padding: 0.5em;
  text-decoration: none;
  width: 100%;
  border-style: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

BUTTON.waiting-list {
  font-size: smaller;
  background-color: rgb(69, 235, 69);
  padding: 0.5em;
  text-decoration: none;
  width: 100%;
  border-style: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
}

BUTTON:disabled {
  background-color: lightgray;
  cursor: unset;
}

.purchase-data {
  font-family: 'Courier New', Courier, monospace;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

DIV.purchase-success {
  background-color: rgb(199, 252, 197);
}

.purchase-success P {
  font-size: unset;
}
</style>
